<template>
  <div class="bgc-white p-20 bd" id="mainContent">
    <h4 class="c-grey-900">إضافة مرحلة</h4>
    <div class="mT-30">
      <form @submit="checkForm">
        <div class="form-row mT-20">
          <div class="form-group col-md-7">
            <label for="inputEmail4">إسم المرحلة</label
            ><input type="name" class="form-control" id="inputEmail4" v-model="subjectName" required  style="padding:17px 0"/>
          </div>
          <div class="form-group col-md-5" style="text-align: right">
            <label for="">أيقونة المادة</label
            >
                <sui-dropdown
                selection
                search
                fluid
                :options="[
                    {image:{src: require('@/assets/static/images/subjects/1.png')}, text:'الفقه' , value:'1.png' },
                    {image:{src: require('@/assets/static/images/subjects/2.png')}, text:'النحو' , value:'2.png' },
                    {image:{src: require('@/assets/static/images/subjects/3.png')}, text:'القران الكريم' , value:'3.png' },
                    {image:{src: require('@/assets/static/images/subjects/4.png')}, text:'العلوم' , value:'4.png' },
                    {image:{src: require('@/assets/static/images/subjects/5.png')}, text:'الرياضيات' , value:'5.png' },
                    {image:{src: require('@/assets/static/images/subjects/6.png')}, text:'اللغة العربية' , value:'6.png' },
                    {image:{src: require('@/assets/static/images/subjects/7.png')}, text:'الغذاء و الصحة' , value:'7.png' },
                    {image:{src: require('@/assets/static/images/subjects/8.png')}, text:'الإنجليزي' , value:'8.png' },
                    {image:{src: require('@/assets/static/images/subjects/9.png')}, text:'اهرامات' , value:'9.png' },
                    {image:{src: require('@/assets/static/images/subjects/10.png')}, text:'كيمياء' , value:'10.png' },
                    {image:{src: require('@/assets/static/images/subjects/11.png')}, text:'جغرافيا' , value:'11.png' },
                    {image:{src: require('@/assets/static/images/subjects/12.png')}, text:'تقنية' , value:'12.png' },
                    {image:{src: require('@/assets/static/images/subjects/13.png')}, text:'أحياء' , value:'13.png' },
                    {image:{src: require('@/assets/static/images/subjects/14.png')}, text:'تاريخ' , value:'14.png' },
                    {image:{src: require('@/assets/static/images/subjects/15.png')}, text:'فنون' , value:'15.png' },
                    {image:{src: require('@/assets/static/images/subjects/16.png')}, text:'الأرض' , value:'16.png' },
                    {image:{src: require('@/assets/static/images/subjects/17.png')}, text:'ملابس' , value:'17.png' },

                ]"
                v-model="pic"
                />
          </div>
          
        </div>

        <div class="form-row mT-20">
          <input id="fileUpload" type="file" hidden accept=".pdf" @change="onFileSelected"/>
          <sui-button
          type="button"
          class="btn btn-primary mL-10"
          :content="book == null ? 'إختر كتاب' : book.name"
          icon="book"
          @click="chooseFiles"
          label-position="left"
          :disabled="loading"
        />
        </div>

        <div class="form-row mT-20">
          <loading v-show="loading" />
        <sui-button
          type="submit"
          positive
          class="btn btn-primary mL-10"
          :content="$t('add')"
          icon="check"
          label-position="left"
          :disabled="loading"
          v-show="!loading"
        />
        <sui-button
          type="button"
          :content="$t('cancel')"
          :disabled="loading"
          v-show="!loading"
          @click="$emit('closePanel', {})"
        />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classId: {type: String},
    reload: { type: Function },
  },
  data() {
    return {
      subjectName: "",
      pic: "1.png",
      book: null,
      loading: false
    }
  },
  methods: {
    onFileSelected: function(event) {
      this.book = event.target.files[0];
          },
      chooseFiles: function() {
        document.getElementById("fileUpload").click()
    },
    checkForm: function (e) {
      e.preventDefault();
      this.loading = true;
      this.addSubject();
    },
    addSubject: function () {
      
      let data = new FormData();

      data.append('book', this.book);
      data.append('ar_name',this.subjectName);
      data.append('en_name','No Name');
      data.append('class_id',this.classId);
      data.append('pic',this.pic);


      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .post(
          "https://yalla-emtihan.com/backend/subjects/addSubject",
          data,
          { headers }
        )

        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 201:
                this.$emit("closePanel", {});
                this.success(this.$t("success"));
                this.reload();
                break;
            } 
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
            }
          }
        );
    },
    error: function (message) {
      this.$toast.error(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    success: function () {
      this.$toast.success(this.$t("success"), {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style scoped>
#mainContent {
  text-align: right;
  direction: rtl;
}
.ui {
    text-align: right !important;
}

</style>